/*eslint-disable*/
import { createRouter,createWebHistory,createWebHashHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import introductionView from '../views/Introduction'
import editIntroduction from '../views/Introduction/editIntroduction'
import newsView from '../views/news'
import editNewsView from '../views/news/editNews'
import centerView from '../views/center'
import profileView from '../views/center/profileView'
import userView from '../views/center/userView'
import courseView from '../views/course'
import courseDetail from '../views/course/courseDetail'
import signRecord from '../views/course/signRecord'
import contactView from '../views/contact'
import contactDetail from '../views/contact/contactDetail'
import contactSet from '../views/contact/contactSet'
import contactChart from '../views/setting/contactChart'
import contactChart1 from '../views/setting/contactChart1'
import contactChart2 from '../views/setting/contactChart2'
import contactChart3 from '../views/setting/contactChart3'
import medicationList from '../views/setting/medicationList'
import signView from '../views/setting/signView'
import transmitView from '../views/setting/transmitView'
import learnSituation from '../views/setting/learnSituation'
import tipView from '../views/setting/tipView'
import temperatureView from '../views/setting/temperatureView'
import eventView from '../views/setting/eventView'
import feedbackView from '../views/setting/feedbackView'
import chatView from '../views/chatroom/chatView'
import chatroom from '../views/chatroom' 
import messageView from '../views/message'
import studentQRView from '../views/qrcode/studentQRView'
import studentScanView from '../views/qrcode/studentScanView'
import signInOutView from '../views/qrcode/signInOutView'
import dishOrderScanView from '../views/qrcode/dishOrderScanView'
import signInScanView from '../views/qrcode/signInScanView'
import signOutScanView from '../views/qrcode/signOutScanView'
import recordView from '../views/record'
import latestNewsView from '../views/news/latestNews.vue'
import loginView from '../views/login/'
import registerView from '../views/register/'
import checkView from '../views/login/checkView.vue'
import signatureView from '../views/signatureView.vue'
import reviewParentView from '../views/reviewParent'
import errorView from '../views/errorView.vue'
import medicationRecoid from '../views/medicationRecoid/medicationRecoid.vue'
import studentView from '../views/setting/studentView.vue'
import temperatureParent from '../views/setting/temperatureParent.vue'
import learnSituationParent from '../views/setting/learnSituationParent.vue'
import transmitParent from '../views/setting/transmitParent.vue'
import formView from '../views/setting/formView.vue'
import formParent from '../views/setting/formParent.vue'
import menuParent from '../views/setting/menuParent.vue'
import { useStore } from "vuex";
import { authorize } from '@/api/api'

const routes = [
  {
    path: '',
    name: 'home',
    component: HomeView
  },
  // {
  //   path: '/?code=:code&state=:state',
  //   name: 'lineLogin',
  //   component: HomeView
  // },
  {
    path: '/',
    name: 'home1',
    component: HomeView
  },
  {
    path: '/home',
    name: 'home2',
    component: HomeView
  },
  {
    path: '/introduction',
    name: 'introduction',
    component: introductionView
  },
  {
    path: '/news',
    name: 'news',
    component: newsView
  },
  {
    path: '/editNews',
    name: 'editNews',
    component: editNewsView,
  },
  {
    path: '/editIntroduction',
    name: 'editIntroduction',
    component: editIntroduction,
  },
  {
    path: '/center',
    name: 'center',
    component: centerView
  },
  {
    path: '/profile',
    name: 'profile',
    component: profileView
  },
  {
    path: '/user',
    name: 'user',
    component: userView,
  },
  {
    path: '/course',
    name: 'course',
    component: courseView
  },
  {
    path: '/courseDetail',
    name: 'courseDetail',
    component: courseDetail,
    beforeEnter: (to) => {
      if((!to.query?.CLSNO) || (!to.query?.HDYDATE)){
        return '/course'
      }
    },
  },
  {
    path: '/signRecord',
    name: 'signRecord',
    component: signRecord,
    beforeEnter: (to) => {
      if((!to.query?.CLSNO) || (!to.query?.HDYDATE)){
        return '/course'
      }
    },
  },
  {
    path: '/contact',
    name: 'contact',
    component: contactView,
    beforeEnter: () => {
      const store = useStore()
      // console.log(store.state.roleID)
      // console.log('to',to)
      // console.log('from',from)
      if(store.state.roleID == 3){
        return '/contactDetail'
      }
      // reject the navigation
      // return false
    },
  },
  {
    path: '/contactDetail',
    name: 'contactDetail',
    component: contactDetail
  },
  {
    path: '/contactSet',
    name: 'contactSet',
    component: contactSet,
  },
  {
    path: '/contactChart',
    name: 'contactChart',
    component: contactChart,
  },
  {
    path: '/contactChart1',
    name: 'contactChart1',
    component: contactChart1,
  },
  {
    path: '/contactChart2',
    name: 'contactChart2',
    component: contactChart2,
  },
  {
    path: '/contactChart3',
    name: 'contactChart3',
    component: contactChart3,
  },
  {
    path: '/medicationList',
    name: 'medicationList',
    component: medicationList,
  },
  {
    path: '/signView',
    name: 'signView',
    component: signView,
  },
  {
    path: '/transmitView',
    name: 'transmitView',
    component: transmitView,
  },
  {
    path: '/learnSituation',
    name: 'learnSituation',
    component: learnSituation,
  },
  {
    path: '/tipView',
    name: 'tipView',
    component: tipView,
  },
  {
    path: '/reviewParentView',
    name: 'reviewParentView',
    component: reviewParentView,
  },
  {
    path: '/temperatureView',
    name: 'temperatureView',
    component: temperatureView,
  },
  {
    path: '/eventView',
    name: 'eventView',
    component: eventView,
  },
  {
    path: '/feedbackView',
    name: 'feedbackView',
    component: feedbackView,
  },
  {
    path: '/chatView',
    name: 'chatView',
    component: chatView,
  },
  {
    path: '/chatroom',
    name: 'chatroom',
    component: chatroom,
  },
  {
    path: '/messageView',
    name: 'messageView',
    component: messageView,
  },
  {
    path: '/studentQR',
    name: 'studentQR',
    component: studentQRView,
  },
  {
    path: '/studentScan',
    name: 'studentScan',
    component: studentScanView,
  },
  {
    path: '/signInOut',
    name: 'signInOut',
    component: signInOutView,
  },
  {
    path: '/dishOrderScan',
    name: 'dishOrderScan',
    component: dishOrderScanView,
  },
  {
    path: '/signInScan',
    name: 'signInScan',
    component: signInScanView,
  },
  {
    path: '/signOutScan',
    name: 'signOutScan',
    component: signOutScanView,
  },
  {
    path: '/recordView',
    name: 'recordView',
    component:recordView,
  },
  {
    path: '/latestNewsView',
    name: 'latestNewsView',
    component: latestNewsView,
  },
  {
    path: '/login',
    name: 'login',
    component: loginView,
  },
  {
    path: '/register',
    name: 'register',
    component: registerView,
  },
  {
    path: '/checkView',
    name: 'checkView',
    component: checkView,
  },
  {
    path: '/signatureView',
    name: 'signatureView',
    component: signatureView,
  },
  {
    path: '/studentView',
    name: 'studentView',
    component: studentView,
  },
  { 
    path: '/:pathMatch(.*)*', 
    component: errorView 
  },
  {
    path: '/medicationRecoid',
    name: 'medicationRecoid',
    component: medicationRecoid,
  },
  {
    path: '/temperatureParent',
    name: 'temperatureParent',
    component: temperatureParent,
  },
  {
    path: '/learnSituationParent',
    name: 'learnSituationParent',
    component: learnSituationParent,
  },
  {
    path: '/transmitParent',
    name: 'transmitParent',
    component: transmitParent,
  },
  {
    path: '/formView',
    name: 'formView',
    component: formView,
  },
  {
    path: '/formParent',
    name: 'formParent',
    component: formParent,
  },
  {
    path: '/menuParent',
    name: 'menuParent',
    component: menuParent,
  },
  
  // {
  //   path: '/about',
  //   name: 'about',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  // }
]


const router = createRouter({
  history: createWebHashHistory(),
  base: '/',
  routes
})

//可在未登入時進入
const allow = ['register','login','home','home1','home2','latestNewsView','checkView','signatureView']
//需特定權限
const authority = [
  'editNews',
  'editIntroduction',
  'user',
  'contactSet',
  'contactChart',
  'medicationList',
  'signView',
  'transmitView',
  'learnSituation',
  'temperatureView',
  'chatView',
  'qrcodeView',
  'recordView'
]

router.beforeEach((to, from) => {
  const store = useStore()
  // console.log('to',to.name)
  if(!(allow.includes(to.name) || store.state.isLogin)){
    return '/'
  }
  if((authority.includes(to.name)) && (store.state.roleID == 3)){
    return '/'
  }

  if(store.state.isLogin && (!allow.includes(to.name))){
    
    authorize().then((res) => {
      if(res.data?.status){
        store.commit('setUser',res.data.data)
      }else{
        store.commit('clearToken')
        return '/loginView'
      }
    }).catch((err) => { })
  }

  // explicitly return false to cancel the navigation
  // return false
})

export default router
