<template>
    <div class="flex flex-col justify-center items-center">
        <div class="w-[90%] my-1 px-2 flex flex-col justify-center items-center break-all">
            <div class="w-full flex flex-col justify-center items-center break-all">
                
            </div>
        </div>
        <div class="w-[90%] my-1 px-2 flex flex-col justify-center items-center break-all">
            <div class="w-full flex flex-col justify-center items-center break-all text-xl">
                <b>便當訂購</b>
            </div>
        </div>
        <div class="w-[90%] my-1 px-2 flex flex-col justify-center items-center break-all">
            <div class="w-full flex flex-col justify-center items-center break-all">
                請掃描學生 QR Code 
            </div>
        </div>
        <div class="qrcodeStyle relative w-[300px] h-[300px] my-1" style="max-width:75vw; max-height:75vw;">
            <QrcodeStream
                :paused="paused"
                @camera-on="onReady"
                :torch="torchActive"
                :track="paintBoundingBox"
                @detect="onDetect"
                @error="onError">
            </QrcodeStream>
            <div v-if="stopStatus" 
                class="absolute top-0 left-0 text-black bg-white flex flex-wrap justify-center items-center"
                style="width:300px; height:300px; max-width:75vw; max-height:75vw;"
                >
                切換中
            </div>
        </div>
        <div class="w-full h-[30px] text-sm flex flex-wrap justify-center items-center">
        </div>
        <div class="w-full text-sm flex flex-wrap justify-center items-center">
            <button
                @click="switchCamera"
                class=" bg-slate-100 hover:bg-slate-50 font-bold mx-1 py-1 px-2 md:py-2 md:px-3 rounded">
                相機反轉
            </button>
            <!-- <button
                @click="clickFlash"
                class=" bg-slate-100 hover:bg-slate-50 font-bold mx-1 py-1 px-2 md:py-2 md:px-3 rounded">
                打開手電筒
            </button> -->
            <button
                @click="openQuery"
                class=" bg-slate-100 hover:bg-slate-50 font-bold mx-1 py-1 px-2 md:py-2 md:px-3 rounded">
                手動登記
            </button>
        </div>
        <div class="w-full h-[30px] text-sm flex flex-wrap justify-center items-center">
        </div>
        <div v-if="orderShowStatus" 
            class="w-[95%] h-[auto] text-base md:text-xl rounded-lg bg-slate-50 p-1 shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] flex flex-wrap items-center">
            <div class="mr-auto flex flex-wrap items-center">
                <div>{{ studentName }} 小朋友</div>
            </div>
            <strong class="ml-auto">目前點數：{{ totalPoints }}</strong>
        </div>
        <div
            class="w-[95%] mx-auto text-base md:text-xl rounded-lg bg-slate-50 p-4 shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)]">
            <div class="space-y-4">
                <div class="border-b border-gray-200 pb-4 last:border-b-0">
                    <div class="flex justify-between items-center mb-2">
                        <span class="font-bold text-lg text-gray-700">{{ dish.dayName }}</span>
                        <span class="text-sm text-gray-500">{{ dish.date }}</span>
                    </div>
                    <div class="space-y-2">
                        <div v-if="dish && dish.selectedFoods && (dish.selectedFoods.length === 0 || dish.selectedFoods.every(food => !food))"
                            class="bg-white p-2 rounded-md shadow-sm text-gray-400 italic">
                            未選擇菜品
                        </div>
                        <template v-else>
                            <div v-for="(food, index) in dish.selectedFoods" :key="index"
                                class="bg-white p-2 rounded-md shadow-sm">
                                <div v-if="food" class="text-gray-700">
                                    <div 
                                        v-if="food.pictureUrl" 
                                        class="w-full flex justify-center"
                                        >
                                        <div 
                                            class="w-[25vw] h-[25vw] md:w-[10vw] md:h-[10vw] bg-[#808080] bg-cover bg-center bg-no-repeat"
                                            >
                                            <img class="w-full h-full" :src="food.pictureUrl" alt="">
                                        </div>
                                    </div>
                                    {{ food.foodName }}
                                    <span class="text-sm text-gray-500 ml-2">
                                        ({{ food.point }}點) | {{ GetTypeName(food.type) }}
                                    </span>
                                </div>
                                <button v-if="orderShowStatus && (!haveTodayOrder || (haveTodayOrder && orderStatus[food.dishRecordId]))" @click="Order(food)" :class="[
                                    'w-[auto] text-sm md:text-xl mx-[2px] py-[1px] px-[2px] border-[1px] rounded',
                                    orderStatus[food.dishRecordId]
                                        ? 'text-gray-400 border-gray-400 cursor-not-allowed'
                                        : 'text-[#22c55e] border-[#22c55e] hover:bg-[#22c55e] hover:text-white'
                                    ]" :disabled="orderStatus[food.dishRecordId]">
                                    {{ orderStatus[food.dishRecordId] ? '已訂購' : '訂購' }}
                                </button>
                            </div>
                        </template>
                    </div>
                </div>
            </div>
        </div>
        <div class="w-full h-[80px] text-sm flex flex-wrap justify-center items-center">
        </div>
        <Teleport to="body">
            <conversationView v-if="queryStatus">
                <template v-slot:header>
                    <div
                        class="w-full py-1 px-2 md:py-2 md:px-4 text-sm md:text-lg text-[#808080] flex flex-wrap items-center justify-center">
                        <div class="mr-auto flex flex-wrap items-center">
                            <div>手動登記</div>
                        </div>
                    </div>
                </template>
                <template v-slot:content>
                    <div
                        class="line-style w-full text-sm md:text-lg text-[#000000] flex flex-wrap items-center justify-center">
                        確認學號
                    </div>
                    <div
                        class="line-style w-full text-sm md:text-lg text-[#000000] flex flex-col items-center justify-center">
                        <span>學號：</span>
                        <el-input 
                            v-model="schoolStudentId"
                            class="w-[80%]"
                            maxlength="30"
                            type="text"
                        />
                    </div>
                </template>
                <template v-slot:control>
                    <div class="absolute w-full bottom-4 flex flex-wrap justify-center items-center">
                        <button @click="Query"
                            class="min-w-[20%] bg-blue-500 hover:bg-blue-600 text-white font-bold mx-2 py-1 px-2 md:py-2 md:px-3 rounded">
                            查詢
                        </button>
                        <button @click="cancel"
                            class="min-w-[20%] bg-gray-500 hover:bg-[#999999] text-white font-bold mx-2 py-1 px-2 md:py-2 md:px-3 rounded">
                            取消
                        </button>
                    </div>
                </template>
            </conversationView>
        </Teleport>
        <Teleport to="body">
            <dialogView v-if="dialogStatus">
                <template v-slot:message>
                    <div
                        class="w-[80%] h-[80px] text-lg flex flex-col justify-center items-center break-all overflow-hidden">
                        {{message}}
                    </div>
                </template>
                <template v-slot:control>
                    <div class="absolute w-full bottom-4 flex flex-wrap justify-center items-center">
                        <button
                            @click="cancel"
                            class="min-w-[20%] bg-blue-500 hover:bg-blue-600 text-white font-bold mx-2 py-1 px-2 md:py-2 md:px-3 rounded">
                            確定
                        </button>
                    </div>
                </template>
            </dialogView>
        </Teleport>
        <Teleport to="body">
            <conversationView type="default" v-if="modalStatus">
                <template v-slot:header>
                    <div
                        class="w-full py-1 px-2 md:py-2 md:px-4 text-sm md:text-lg text-[#808080] flex flex-wrap items-center justify-center">
                        <div class="mr-auto flex flex-wrap items-center">
                            <div>{{ studentName }} 小朋友</div>
                        </div>
                    </div>
                </template>
                <template v-slot:content>
                    <div
                        class="line-style w-full text-sm md:text-lg text-[#000000] flex flex-wrap items-center justify-center">
                        確認訂單
                    </div>
                    <div
                        class="line-style w-full text-sm md:text-lg text-[#000000] flex flex-col items-center justify-center">
                        <p class="mb-2">日期：{{ dish.dayName }} ({{ dish.date }})</p>
                        <span>{{ currentOrder.food.foodName }} ({{ currentOrder.food.point }}點)</span>
                    </div>
                </template>
                <template v-slot:control>
                    <div class="absolute w-full bottom-1 md:bottom-2 flex flex-wrap justify-center items-center">
                        <button @click="Confirm"
                            class="min-w-[20%] bg-blue-500 hover:bg-blue-600 text-white font-bold mx-2 py-1 px-2 md:py-2 md:px-3 rounded">
                            確認
                        </button>
                        <button @click="cancel"
                            class="min-w-[20%] bg-gray-500 hover:bg-[#999999] text-white font-bold mx-2 py-1 px-2 md:py-2 md:px-3 rounded">
                            取消
                        </button>
                    </div>
                </template>
            </conversationView>
        </Teleport>
    </div>
</template>
<script setup>
/*eslint-disable*/
import { ref,computed,onMounted,provide, reactive } from 'vue';
import { getDataByDate, getDataListByDishId, getFoodDetail, getFoodDataList, 
    getOrderRecordByStudentId, CreateOrderRecord, editStudentForPoint, getStudentBySchoolStudentId,
    getClassSelect, qrCodeGetStudent } from '@/api/api'
import { useStore } from "vuex";
import { QrcodeStream } from 'vue-qrcode-reader'
import dialogView from "@/components/dialogView.vue"
import conversationView from "@/components/conversationView.vue"
import { FailedToNegotiateWithServerError } from '@microsoft/signalr/dist/esm/Errors';

onMounted(() => {
})

const store = useStore()
const isMobile = computed(() => {
    return store.state.isMobile
})
/*
torchActive 手電筒狀態
result  顯示文字
resultArr qrcode資料陣列
error 錯誤文字
facingMode 鏡頭訊息
stopStatus loading狀態
paused 暫停狀態
dialogStatus 彈出視窗狀態
*/
const torchActive = ref(false)
const result = ref('')
const resultArr = ref([])
const error = ref('')
const message = ref('')
const facingMode = ref('environment')
const stopStatus = ref(true)
const paused = ref(false)
const queryStatus = ref(false)
const dialogStatus = ref(false)
const modalStatus = ref(false)

const classOptions = ref([
        {
            value: '',
            label: '',
        }
    ])

const studentName = ref('')
const totalPoints = ref(0)
const classId =  ref('')
const className = ref('')
const orderStatus = reactive({});
const haveTodayOrder = ref(false)
const orderShowStatus = ref(false)
    
const getClassOptions= async() => {
  await getClassSelect().then((res) => {
    if(res.data.status){
        classOptions.value = res.data.data.optionList
        classId.value = res.data.data.optionList[0].value
      }
  }).catch((err) => { })
}

const schoolStudentId =  ref('')

const openQuery = async () => {
    queryStatus.value = true
}

const Query = async () => {
    const formData = new FormData();
    formData.append("schoolStudentId", schoolStudentId.value);
    const res = await getStudentBySchoolStudentId(formData);
    
    if (res.data.status) {
        apiData.value = res.data;
        message.value = res.data.message + '，' + res.data.data.userName + '小朋友'
        studentId.value = res.data.data.id
        studentName.value = res.data.data.userName
        totalPoints.value = res.data.data.point
        orderShowStatus.value = true
        haveTodayOrder.value = false
        checkOrderStatus(dish);
    } else {
        apiData.value = res.data;
        message.value = res.data.message;
    }
    cancel()
    dialogStatus.value = true
    paused.value = true
}

const dishRecordId =  ref('')
const studentId =  ref('')

const currentOrder = ref({
    food: {},
    totalPoint: 0
});

const Order = async (food) => {
    modalStatus.value = true
    currentOrder.value = {
        food: food,
        totalPoint: food.point
    };
}

const Confirm = async () => {
    if (totalPoints.value < currentOrder.value.totalPoint) {
        alert(`您目前點數:${totalPoints.value}不足支付${currentOrder.value.totalPoint}`)
    } else {
        const formData = new FormData();
        formData.append("id", studentId.value);
        formData.append("point", (0 - currentOrder.value.totalPoint));
        const res = await editStudentForPoint(formData);
        if (res.data.status) {
            const formData1 = new FormData();
            formData1.append("dishRecordId", currentOrder.value.food.dishRecordId);
            formData1.append("studentId", studentId.value);
            formData1.append("dishId", dish.value.id);
            const res1 = await CreateOrderRecord(formData1);
            if (res1.data.status) {
                alert('訂購成功')
                init()
            }
            else {
                alert(res1.data.message)
            }
        }
        else {
            alert(res.data.message)
        }
    }
    modalStatus.value = false
}

const checkOrderStatus = async (dish) => {
    if (!dish || !dish.value.id) {
        console.error('Invalid dish object:', dish);
        return;
    }

    for (const dishRecord of dishRecords.value) {
        const formData = new FormData();
        formData.append("dishRecordId", dishRecord.id);
        formData.append("studentId", studentId.value);
        formData.append("dishId", dish.value.id);

        try {
            const res = await getOrderRecordByStudentId(formData);
            if (res.data && res.data.status) {
                orderStatus[dishRecord.id] = Boolean(res.data.data);
                if (Boolean(res.data.data)) {
                    haveTodayOrder.value = true;
                }
            } else {
                console.warn('Unexpected API response:', res);
                orderStatus[dishRecord.id] = false;
            }
        } catch (error) {
            console.error('檢查訂單狀態失敗:', error);
            orderStatus[dishRecord.id] = false;
        }
    }
};

const GetTypeName = (type) => {
    switch (type) {
        case 0:
            return '葷';
        case 1:
            return '素';
        default:
            return '';
    }
}

const dayData = ref(new Date())
const getDishData = async () => {
    const formData = new FormData();
    formData.append("date", dayData.value.toDateString());
    const res = await getDataByDate(formData);
    if (res.data.status) {
        await setCurrentWeek(res.data.data);
    }
}


const init = async () => {
    orderShowStatus.value = false
    haveTodayOrder.value = false
    await getDishData()
}
init()

const formatDate = (date) => {
    const d = new Date(date);
    const year = d.getFullYear();
    const month = String(d.getMonth() + 1).padStart(2, '0');
    const day = String(d.getDate()).padStart(2, '0');

    return `${year}/${month}/${day}`;
};

const dish = ref({})
const dishRecords = ref([])
const setCurrentWeek = async (item) => {
    const formattedDate = formatDate(item.dishDate);
    const formData = new FormData();
    formData.append("DishId", item.id);
    const dishRes = await getDataListByDishId(formData);
    let selectedFoods = [];
    if (!dishRes.data.status) return null;
    dishRecords.value = dishRes.data.data;
    for (const dishRecord of dishRecords.value) {
        const formData1 = new FormData();
        formData1.append("id", dishRecord.foodId);
        const foodRes = await getFoodDetail(formData1);

        if (foodRes.data.status) {
            let food = foodRes.data.data
            food.dishRecordId = dishRecord.id
            selectedFoods.push(food)
        }
    }

    dish.value = {
        id: item.id,
        date: formattedDate,
        dayName: item.dishName,
        selectedFoods: selectedFoods
    };
};

getClassOptions();

const changeClass= async() => {
  store.commit('setClassId',classId.value)
  let name = ''
  for (let key in classOptions.value) {
    if(classOptions.value[key].value == classId.value) {
        name = classOptions.value[key].label
        break
    }
  }
  className.value = name
  store.commit('setClassName',name)
}

//掃描框線設定
const paintBoundingBox = (detectedCodes, ctx) => {
    for (const detectedCode of detectedCodes) {
        const {
            boundingBox: { x, y, width, height }
        } = detectedCode

        ctx.lineWidth = 2
        ctx.strokeStyle = '#007bff'
        ctx.strokeRect(x, y, width, height)
    }
}
//偵測到error觸發
const onError = (err) => {
    if (err.name === 'NotAllowedError') {
        error.value += '需要授予攝像頭訪問權限。'
    } else if (err.name === 'NotFoundError') {
        error.value += '此設備上沒有攝像頭。'
    } else if (err.name === 'NotSupportedError') {
        error.value += '需要安全環境（HTTPS）。'
    } else if (err.name === 'NotReadableError') {
        error.value += '攝像機正在被其他應用使用。'
    } else if (err.name === 'OverconstrainedError') {
        error.value += '已安裝的攝像頭不適用。'
    } else if (err.name === 'StreamApiNotSupportedError') {
        error.value += '此瀏覽器不支持Stream API。'
    } else if (err.name === 'InsecureContextError') {
        error.value += '僅允許在安全環境下訪問攝像頭。請使用HTTPS。'
    } else {
        error.value += err.message
    }

    error.value += '請通知系統管理員。'
}
let loadStatus = false
const apiData = ref({})
//偵測到QRCODE觸發
const onDetect = async(detectedCodes) => {
    if(loadStatus) {
        return false
    }
    orderShowStatus.value = false
    loadStatus = true
    //console.log('detectedCodes',detectedCodes)
    resultArr.value = detectedCodes.map(code => code.rawValue)
    result.value = detectedCodes[0].rawValue

    let formData = new FormData();
    let value = result.value
    formData.append('classId', classId.value)
    formData.append('content', value)
    await qrCodeGetStudent(formData).then((res) => {
        if(res.data.status){
            apiData.value = res.data;
            message.value = res.data.message + '，' + res.data.data.userName + '小朋友'
            studentId.value = res.data.data.id
            studentName.value = res.data.data.userName
            totalPoints.value = res.data.data.point
            orderShowStatus.value = true
            haveTodayOrder.value = false
            checkOrderStatus(dish);
        }else{
            apiData.value = res.data;
            message.value = res.data.message;
        }
    }).catch((err) => { })

    dialogStatus.value = true
    paused.value = true
}
//切換前後鏡頭
const switchCamera = () => {
    switch (facingMode.value) {
        case 'environment':
            facingMode.value = 'user'
            break
        case 'user':
            facingMode.value = 'environment'
            break
    }
}
//切換手電筒
const clickFlash = async() => {
    stopStatus.value = true
    paused.value = true
    torchActive.value = !torchActive.value
    await delay()
    paused.value = false
}
//延遲設定
const delay = () => {   
    return new Promise(function (resolve, reject) {
        setTimeout(function () {
            resolve('delay');
        }, 2000);
    });
}
//qrcode元件初始化時觸發
const onReady = (item) => {
    stopStatus.value = false
    //console.log('onReady',item)
}
//關閉彈出視窗
const cancel = () => {
    loadStatus = false
    dialogStatus.value = false
    modalStatus.value = false
    queryStatus.value = false
    paused.value = false
}

provide('cancel', cancel)

</script>
<style lang="scss" scoped>
.qrcodeStyle div {
    width: 100%;
    height: 100%;
}
</style>
  